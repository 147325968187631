import React from "react"
import { Error } from "~sections/utility"
import { PageWrapper } from "~components/Core"
import FooterSection from "~sections/utility/Footer"

export default function errorPage() {
  return (
    <PageWrapper innerPage={true}>
      <Error />
      <FooterSection />
    </PageWrapper>
  )
}
